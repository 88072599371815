<template>
  <v-container fluid class="bg_img">
    <v-app-bar dark hide-on-scroll src="@/assets/img/appbar.jpg" app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $route.meta.desc }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      color="indigo"
      dark
      src="@/assets/img/navbar.jpg"
      app
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="@/assets/img/avatar_google.png" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          router
          :to="{ name: item.route }"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block router :to="{ name: 'logout' }">
            Cerrar Sesión
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>

    <v-footer absolute>
      <v-col class="text-right" cols="12">
        <v-icon>mdi-spa</v-icon><span class="aligned-icon">05082021</span>
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  computed: {
    user: function() {
      return this.$store?.state?.user?.name;
    },
  },
  data: () => ({
    drawer: false,
    items: [
      { title: "Inicio", icon: "mdi-home-city", route: "home" },
      { title: "ADET", icon: "mdi-bike", route: "adet" },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.aligned-icon {
  position: relative;
  top: 3px;
  margin-left: 3px;
}
.bg_img {
  background: url("../assets/img/washi.png");
  height: 100%;
}
</style>
